import React from 'react'
import Footer from "../Footer"
import Banner from "../Banner"
import Navbar from "../Navbar"
import Nkrumah from "../../images/fire_trains.webp"

function News_five() {
  return (
    <div>
      <Navbar/>
        <Banner/>
        <div className="container">
        <div className="row py-10">
        <div className="col-md-8 mx-auto">
        <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5 uppercase'>Safety First: A Comprehensive Training Session with Ghana National Fire Service</h5>
                <img src={Nkrumah} alt=""  className='about-img'/>
           
            <p className='text-xl pt-4'>At Xminger Advertising, we believe that knowledge is power, especially when it comes to safety in the workplace. This week, we had the incredible opportunity to host a team from the Ghana National Service for an essential training session focused on fire safety and first aid.</p>
            <h6 className='font-bold mt-5 text-2xl sm:text-4xl pb-3'>Why This Training Matters</h6>
            <p className='text-xl pt-4'> Fire incidents and medical emergencies can happen at any time. Being prepared not only protects our team but also ensures a quicker, more effective response to any situation. The Ghana National Service team brought a wealth of knowledge and hands-on experience, equipping us with the skills we need to handle emergencies confidently.</p>
            <h6 className='font-bold mt-5 text-2xl sm:text-4xl pb-3'>Key Highlights from the Session</h6>
            <h6 className='font-bold mt-5 text-xl sm:text-3xl'>1. **Fire Safety Education**</h6>
              <p className='text-xl pt-4'>    - We learned how to identify potential fire hazards in our workspace, from faulty wiring to improper storage of flammable materials.
              <br />- The team demonstrated the proper use of fire extinguishers using the PASS technique (Pull, Aim, Squeeze, Sweep), ensuring we all feel empowered to act in case of a fire. </p>
            
            <h6 className='font-bold mt-5 text-xl sm:text-3xl'>2. **Emergency Evacuation Procedures**</h6>
            <p className='text-xl pt-4'>   - Understanding our emergency exits and practicing evacuation plans is crucial. The session reinforced the importance of being familiar with these routes and conducting regular drills. </p>
            <h6 className='font-bold mt-5 text-xl sm:text-3xl'>3. **First Aid Training**</h6>
            <p className='text-xl pt-4'>   - The team provided hands-on training in basic first aid, covering essential skills like CPR, wound care, and how to respond to common workplace injuries. 
   <br />- We learned that being prepared to assist a colleague in distress can make a significant difference.
 </p>

            <h6 className='font-bold mt-5 text-xl sm:text-3xl'>A Commitment to Safety</h6>
            <p className='text-xl pt-4'> This training was not just an event; it was a step towards fostering a culture of safety at Xminger Advertising. We are incredibly grateful to the Ghana National Service for their dedication and expertise.  </p>
            <p className='text-xl pt-4'> By equipping our staff with the knowledge and skills to handle emergencies, we are making a commitment to ensure a safer and more responsive workplace for everyone. </p>
            <p className='text-xl pt-4'> Let’s continue to prioritize safety, stay informed, and support one another! Together, we can create a secure environment for our team and clients. </p>
            <p className='font-bold mt-5 text-xl sm:text-2xl'> Stay safe and empowered! 🔥🚑 </p>
                
             
                 <div>
                 <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
                  </div>               
            </div>
        </div>
        </div>
       
       <Footer/>
    </div>
  )
}

export default News_five
// import React, { useState, useEffect }  from 'react'
import React from "react"
// import { Link } from 'react-router-dom';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {  Thumbnails, Zoom } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import YouTube from "react-youtube"

import img1 from '../projects_img/aa1.webp'
import img2 from '../projects_img/aa2.webp'
import img3 from '../projects_img/aa3.webp'
import img4 from '../projects_img/aa4_1.webp'
import img5 from '../projects_img/aa5.webp'
import img6 from '../projects_img/aa6.webp'
import img7 from '../projects_img/aa7.webp'
import img8 from '../projects_img/aa8.webp'

// import img2 from '../images/img6.jpg'


function Project() {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);
  const [open8, setOpen8] = React.useState(false);
 

  const videoOptions = { 
    // height:'700',
    width:'100%',
    playerVars: {
      autoplay:0,
    }
  }


  return (
    
    <div className="pb-2" id="project">
      <h4 className='content-title text-center font-bold text-3xl my-5 bg-light py-4'>Featured Projects</h4>
      <div className='container-fluid'>
      <div className="row my-2 "  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">

        <div className="col-md-3 mb-2 img-zoom"><button type="button" onClick={() => setOpen(true)}><img className="img-radius shadow" src={img1} alt="" /></button></div>
        <div className="col-md-3  mb-2 img-zoom"><button type="button" onClick={() => setOpen2(true)}><img className="img-radius shadow" src={img2} alt="" /></button></div>
        <div className="col-md-3  mb-2 img-zoom"><button type="button" onClick={() => setOpen3(true)}><img className="img-radius shadow" src={img3} alt="" /></button></div>
        <div className="col-md-3 img-zoom"><button type="button" onClick={() => setOpen4(true)}><img className="img-radius shadow" src={img4} alt="" /></button></div>
      </div>
      <div className="row mb-2"  data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div className="col-md-3 mb-2  img-zoom"><button type="button" onClick={() => setOpen5(true)}><img className="img-radius shadow" src={img5} alt="" /></button></div>
        <div className="col-md-3 mb-2 img-zoom"><button type="button" onClick={() => setOpen6(true)}><img className="img-radius shadow" src={img6} alt="" /></button></div>
        <div className="col-md-3 mb-2 img-zoom"><button type="button" onClick={() => setOpen7(true)}><img className="img-radius shadow" src={img7} alt="" /></button></div>
        <div className="col-md-3 img-zoom"><button type="button" onClick={() => setOpen8(true)}><img className="img-radius shadow" src={img8} alt="" /></button></div>
      </div>
      <div className="text-center mt-5">  
        {/* <button className="btn btn-outline-danger shadow"><Link to="/projects">Explore More</Link></button>*/}
        <button className="btn btn-outline-danger shadow"><a href="https://youtu.be/zEgBqCL_tYQ?si=o2iK_ObiDPjnks1p">Explore More</a></button>
      </div>
    </div>
    <div className="">
      <div className="row">
        <div className="col-12 mx-auto my-3 text-center" >

        <YouTube videoId="zEgBqCL_tYQ" opts={videoOptions} id="youtube_vd"/>
          <button className="btn btn-outline-danger mt-4 "><a href="https://youtu.be/zEgBqCL_tYQ?si=o2iK_ObiDPjnks1p">Watch More</a></button>
        </div>
      </div>
        
      </div>

     <Lightbox
      plugins={[Thumbnails, Zoom]}
        open={open}
        close={() => setOpen(false)}
        slides={[
          { src: "https://www.xmingeradvertising.com/projects/aa1.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/aa4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/aa5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa8.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro1.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/pro4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro8.webp" },
        ]}
      />
 
<Lightbox
      plugins={[Thumbnails, Zoom]}
        open={open2}
        close={() => setOpen2(false)}
        slides={[
           { src: "https://www.xmingeradvertising.com/projects/aa2.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa1.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/aa4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/aa5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa8.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro1.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/pro4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro8.webp" },
        ]}
      />
      <Lightbox
      plugins={[Thumbnails, Zoom]}
        open={open3}
        close={() => setOpen3(false)}
        slides={[
          { src: "https://www.xmingeradvertising.com/projects/aa3.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa1.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa2.webp "},
          { src: "https://www.xmingeradvertising.com/projects/aa4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/aa5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa8.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro1.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/pro4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro8.webp" },
        ]}
      />
      <Lightbox
      plugins={[Thumbnails, Zoom]}
        open={open4}
        close={() => setOpen4(false)}
        slides={[
         { src: "https://www.xmingeradvertising.com/projects/aa4.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/aa1.webp" },

          { src: "https://www.xmingeradvertising.com/projects/aa5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa8.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro1.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/pro4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro8.webp" },
        ]}
      />
      <Lightbox
      plugins={[Thumbnails, Zoom]}
        open={open5}
        close={() => setOpen5(false)}
        slides={[
          { src: "https://www.xmingeradvertising.com/projects/aa5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/aa4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/aa1.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa8.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro1.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/pro4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro8.webp" },
        ]}
      />
      <Lightbox
      plugins={[Thumbnails, Zoom]}
        open={open6}
        close={() => setOpen6(false)}
        slides={[
          { src: "https://www.xmingeradvertising.com/projects/aa6.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/aa4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/aa5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa1.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa8.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro1.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/pro4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro8.webp" },
        ]}
      />
      <Lightbox
      plugins={[Thumbnails, Zoom]}
        open={open7}
        close={() => setOpen7(false)}
        slides={[
         { src: "https://www.xmingeradvertising.com/projects/aa7.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/aa4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/aa5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa1.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa8.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro1.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/pro4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro8.webp" },
        ]}
      />
      <Lightbox
      plugins={[Thumbnails, Zoom]}
        open={open8}
        close={() => setOpen8(false)}
        slides={[
           { src: "https://www.xmingeradvertising.com/projects/aa8.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/aa4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/aa5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/aa6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/aa1.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro1.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro2.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro3.webp "},
          { src: "https://www.xmingeradvertising.com/projects/pro4.webp" },

          { src: "https://www.xmingeradvertising.com/projects/pro5.webp"},
          { src: "https://www.xmingeradvertising.com/projects/pro6.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro7.webp" },
          { src: "https://www.xmingeradvertising.com/projects/pro8.webp" },
       
        ]}
      /> 
    </div>
    
  )
}

export default Project

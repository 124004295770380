import React from 'react'
import Footer from "./Footer"
import Banner from "./Banner"
import Navbar from "./Navbar"
import img1 from "../images/services/mission.png"
import img2 from "../images/services/vision.png"


function AboutUs() {
  return (
    <div>
       <Navbar/>
       <Banner/>
       <div className="container">
        <div className="row py-10">
        <div className="col-md-8 mx-auto" id='myAbout'>
        <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5'>AboutUs</h5>
               
             <p className='text-xl  pb-5'> Xminger is a professional billboard advertising company, providing visible outdoor advertising locations/spaces that are price competitive with a reputable and inovative advertising style that markets the products and/or services of clients to ensure satisfaction. Our billboard sites are located across all sixteen(16) regions of Ghana on major high ways, market places and other strategic places prone to vehicular as well as human trafic. </p>
            <div className='row bg-slate-1000'>
             <div className="col-md-6">
              <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-3'>Our Mission</h5>
            <p className='text-xl pt-4 text-justify'>
            XMinger Advertising Limited is committed to empowering our clients' success through exceptional billboard advertising services. We strive to be strategic partners, leveraging data-driven insights and creative expertiseI to design compelling campaigns that capture attention, enhance brand awareness, and generate tangible returns. Our mission is to deliver unparalleled value to our clients by consistently exceeding their expectations and maintaining the highest standards of integrity and professionalism.
            </p>
             </div>
            <div className='col-md-6'>
                <img src={img1} alt="" />
              </div>
            </div>

            <div className='row pt-5'>

            <div className='col-md-6'>
                <img src={img2} alt="" />
              </div>
              <div className="col-md-6">
                <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-3'>Our Vision</h5>
            <p className='text-xl pt-4 text-justify'>
            To be the premier provider of innovative, high-impact billboard advertising solutions that transform the way brands engage with their target audiences and drive measurable business results.
            </p>
              </div>
            
            </div>
            <div>
              <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
            </div>  
            </div>
        </div>
        </div>
            <Footer/>
    </div>
  )
}

export default AboutUs
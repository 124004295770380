import React from 'react'
import logo1 from "../../images/img2.jpg"
import Footer from "../Footer"
import Banner from "../Banner"
import Navbar from "../Navbar"

function News_one() {
  return (
    <div>
        <Navbar/>
        <Banner/>
        <div className="container">
        <div className="row py-10">
            <div className="col-md-8 mx-auto">
            <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5'>REVOLUTIONIZING THE ADVERTISING LANDSCAPE IN GHANA WITH WEST AFRICA’S SECOND LARGEST BILLBOARD.</h5>
               
                <img src={logo1} alt="" className='about-img' />
            
            <p className='text-xl pt-4'>Xminger Advertising is a leading force in the advertising industry in Ghana, making waves with it’s groundbreaking campaigns and innovative approaches. In a remarkable feat, the company has recently erected west Africa’s second largest billboard measuring 9m x 40m in the commercial area of Adum cocoa board, located in the heart of Kumasi. In an ambitious move to solidify it’s presence in the Ghanaian advertising landscape, Xminger embarked on a project to erect a monumental billboard in the Adum cocoa board area, a prime location known for it’s traffic and strategic visibility. The strategic placement of the billboard ensures maximum exposure to vast and diverse audience, making it an ideal platform for brands to showcase their products and services. The sheer size of the billboard guarantees high visibility catching the attention of thousands of passersby, creating significant brand awareness and recall. The billboard is equipped with a lighting system which illuminates during the night adding to it’s ambience. Xminger Advertising is keen on innovation and creativity as we look to be at the forefront of the Ghanaian advertising industry..</p>
            <div>
                  <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
                  </div> 
            </div>
        </div>
        </div>
       
        <Footer/>
    </div>
  )
}

export default News_one
import React from 'react'
import Footer from "./Footer"
import Banner from "./Banner"
import Navbar from "./Navbar"

function Printing() {
  return (
    <div>
       <Navbar/>
       <Banner/>
       <div className="container">
        <div className="row py-10">
        <div className="col-md-8 mx-auto">
        <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-2 service-head-text'>Billboard Large Format Printing</h5>
        <div>
          <h6 className='font-bold text-3xl pb-2 pt-4'>Make a Bold Statement with Our Billboard Printing Services</h6>
                <p className='py-2 text-xl text-justify'>At Xminger Advertising, we specialize in large format printing specifically designed for billboards. Our printing solutions help you create impactful advertising that captures attention and drives engagement. </p>
                <h6 className='font-bold mt-5 text-2xl sm:text-3xl pb-2 service-head-text'> Our Billboard Printing Services Include:</h6>
                
                <p className='py-2 text-xl text-justify'><span className='font-bold text-2xl'>High-Resolution Prints:</span> Utilizing advanced printing technology, we ensure your designs are vibrant and visually striking, even from a distance.</p>
                <p className='py-2 text-xl text-justify'><span className='font-bold text-2xl'>Custom Sizes:</span> Whether you need standard dimensions or custom specifications, we can accommodate your needs.</p>
                <p className='py-2 text-xl text-justify'><span className='font-bold text-2xl'>Durable Materials:</span> Our billboards are printed on weather-resistant materials, ensuring longevity and clarity in any environment.</p>
                <p className='py-2 text-xl text-justify'><span className='font-bold text-2xl'>Design Support:</span> Our experienced design team is available to assist you in creating compelling visuals that align with your branding.</p>

                <h6 className='font-bold mt-5 text-2xl sm:text-3xl pb-2 service-head-text'> Why Choose Us?</h6>
                <p className='py-2 text-xl text-justify'><span className='font-bold text-2xl'>Quick Turnaround:</span> We understand the urgency of advertising campaigns and work efficiently to meet your deadlines.</p>
                <p className='py-2 text-xl text-justify'><span className='font-bold text-2xl'>Eco-Friendly Options:</span> We offer sustainable printing materials and processes to help you minimize your environmental impact.</p>
                <p className='py-2 text-xl text-justify'><span className='font-bold text-2xl'>Competitive Pricing:</span> Get high-quality billboard printing without breaking the bank.</p>

                <h6 className='font-bold mt-5 text-2xl sm:text-3xl pb-2 service-head-text'>Contact Us Today!</h6>
                <p className='py-2 text-xl text-justify'>Elevate your advertising strategy with our billboard large format printing services. Reach out for a free consultation and quote!</p>
       </div>
              
              <div>
              <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
            </div>  
            </div>
        </div>
        </div> 
            <Footer/>
    </div>
  )
}

export default Printing
import React from 'react'
import Footer from "./Footer"
import Banner from "./Banner"
import Navbar from "./Navbar"

import img1 from "../images/services/timing.png"
import img2 from "../images/services/achieve.png"
import img3 from "../images/services/reaching.png"
import img4 from "../images/services/purpose.png"

function Consultancy() {
  return (
    <div>
         <Navbar/>
         <Banner/>
         
         <div className="container">
        <div className="row py-10">
        <div className="col-md-10 mx-auto">
        <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5 service-head-text'>Advertising Consultancy</h5>
        <p className='text-xl text-justify pb-5'>At Xminger Advertising, One of our primary role is to advise clients on how to effectively utilize billboard advertising to achieve their marketing objectives. This involves understanding the client's target audience, goals, and budget, and then developing a tailored strategy that maximizes the impact of billboard campaigns</p>
            
            <div className="row bg-slate-1000 ">
           
            <div className="col-md-6">
                <img src={img3} alt="" />
              </div>
              <div className="col-md-6 py-5">
              <h6 className='font-bold mt-5 text-xl sm:text-3xl pb-2 service-head-text mx-4'> Reaching Your Target Audience</h6>
            <p className='text-justify text-xl mx-4'>
           
            Our marketing professionals provide clients with invaluable guidance on where to place their campaigns to most effectively reach their target audience. We leverage our deep industry knowledge and data-driven insights to identify the optimal channels, platforms, and placements to maximize visibility and engagement with your key demographics.

            </p>

              </div>
             
            </div>

            <div className="row">
              <div className="col-md-6 py-5">
              <h6 className='font-bold mt-5 text-xl sm:text-3xl pb-2 service-head-text'> Timing Your Campaigns</h6>
             <p className='text-justify text-xl'>
In addition to strategic placement, the Xminger marketing team also advises clients on the optimal timing for their campaigns. We work closely with you to understand your business objectives and seasonality, ensuring your messages are delivered at the right moments to gain the needed viewership and drive maximum impact.

             </p>

              </div>
              <div className="col-md-6">
              <img src={img1} alt="" />
              </div>
            </div>

            <div className="row bg-slate-1000">
            <div className="col-md-6">
              <img src={img2} alt="" />
              </div>
              <div className="col-md-6 py-5 ">
              <h6 className='font-bold mt-5 text-xl sm:text-3xl pb-2 service-head-text mx-4'>Achieving Optimal Value</h6>
              <p className='text-justify text-xl mx-4'>
              Xminger is committed to helping our clients achieve the best possible return on their marketing investments. Our team ensures campaigns are placed in a way that delivers optimum benefit and outstanding value for your marketing budget. We continuously monitor performance and optimize strategies to drive efficient, high-impact results.
              </p>
              </div>
             
            </div>

            <div className="row">
           
              <div className="col-md-6 py-5">
              <h6 className='font-bold mt-5 text-xl sm:text-3xl pb-2 pt-5 service-head-text'> Purpose-Built Solutions</h6>
            <p className='text-justify text-xl'>
            Our team of experts is purpose-built around the unique challenges and opportunities facing your business. We bring a multidisciplinary approach to every stage of the engagement, leveraging diverse perspectives and creative thinking to develop tailored solutions that meet your specific goals.
              </p>  
             </div>
              <div className="col-md-6">
              <img src={img4} alt="" />
              </div>
            </div>

            <p className='text-xl'>
            Let Xminger's marketing experts ignite growth for your brand. Contact us today to learn more about our capabilities.
            </p>
          
                
            <div>
              <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
            </div>  
            </div>
        </div>
        </div>
            <Footer/>
    </div>
  )
}

export default Consultancy
// import logo from './logo.svg';
import { Route, BrowserRouter,Routes } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import NoPage from './components/NoPage';
import Layout from './components/Layout';
import Home from './components/Home';
// import Teams from './components/Teams';
import NewsOne from './components/news/News_one';
import NewsTwo from './components/news/News_two';
import NewsThree from './components/news/News_three';
import NewsFour from './components/news/News_four';
import NewsFive from './components/news/News_five';
import OurProjects from './components/OurProjects';
import AboutUs from './components/AboutUs';
import Billboard from './components/Billboard';
import Printing from './components/Printing';
import Consultancy from './components/Consultancy';

function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop/>
        <Routes>
            <Route path='/' element={<Layout/>} >
              <Route index element={<Home/>}/>
            </Route>
            <Route path='news_one' element={<NewsOne/>}/>
            <Route path='news_two' element={<NewsTwo/>}/>
            <Route path='news_three' element={<NewsThree/>}/>
            <Route path='news_four' element={<NewsFour/>}/>
            <Route path='news_five' element={<NewsFive/>}/>
            <Route path='projects' element={<OurProjects/>}/>
            <Route path='about_us' element={<AboutUs/>}/>
            <Route path='billboard_rentals' element={<Billboard/>}/>
            <Route path='large_printing' element={<Printing/>}/>
            <Route path='consultancy' element={<Consultancy/>}/>
            <Route path='*' element={<NoPage/>}/>
        </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;

import React, { useState, useEffect }  from 'react'

import img from '../images/banner3.webp'
import img2 from '../images/banner5.webp'
import img3 from '../images/banner7.webp'
import img4 from '../images/banner6.webp'



export default function Banner() {

  const images = [
   
    img4,
   
    img2,
    img3,
    img,
   
  ];

const [currentIndex, setCurrentIndex] = useState(0);

useEffect(() => {
const interval = setInterval(() => {
setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
}, 5000); // Change every 5 seconds
return () => clearInterval(interval); // Cleanup interval on component unmount
}, [images.length]);

  return (
    <div className=''>
      <div className='text-slate-900 shadow ' id='nav-bar' style={{backgroundImage: `url(${images[currentIndex]})`}}> 
      </div>   
    </div>
  )
}

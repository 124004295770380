import React from 'react'
import Footer from "../Footer"
import Banner from "../Banner"
import Navbar from "../Navbar"
import logo2 from "../../images/img4.jpg"

function News_four() {
  return (
    <div>
      <Navbar/>
        <Banner/>
        <div className="container">
        <div className="row py-10">
        <div className="col-md-8 mx-auto">
        <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5'>25 YEARS OF REIGN: CELEBRATING THE REMARKABLE LEGACY OF ASANTEHENE OTUMFUOR OSEI TUTU II</h5>
                <img src={logo2} alt="" className='about-img' />
          
                <p className='text-xl  pt-4'>We celebrate Asantehene, Otumfour Osei Tutu II. ‘Otumfour wo nkwa so’. It’s been 25 years since His Royal Majesty Otumfour Osei Tutu II was enthroned as the King over all of Asanteman and as the sole custodian of the Asante Kingdom, its powers, the land, its customs and traditions and the people at large. For a quarter of a century, Otumfour Osei Tutu II has ruled Asanteman with firmness, justice and fairness. Asanteman has prospered and for that, we hail Your Majesty. </p>
                <p className='text-xl  pt-4'> As we celebrate 150 years of history, we mount this billboard to celebrate centuries of Asanteman sovereignty. It is now a hundred and fifty years since Asanteman and the British Empire went at it in war. A war that would ultimately be called the “Sagrenti War”.




As proud as the Asantes are about their rich tradition and customs, it is only befitting that the publicity of the 25th anniversary of Asantehene’s coronation be done in style, as such we at Xminger Advertising worked tirelessly to bring this to light. </p>
<p className='text-xl  pt-4'>The 150 years of “Sagrenti war” art work is mounted on one of our biggest billboards measuring 9m x 40m located at Adum cocoa board, a prime location in Kumasi. The billboard is strategically placed which ensures maximum exposure to vast and diverse vehicular and human traffic.




Few days after mounting, the upcoming anniversary has become the talk of town which proves how effective outdoor advertising is in this day and age.</p>
<p className='text-xl  pt-4'> As a company which is keen on innovation, creativity and service, we look forward to pushing boundaries to help create awareness, educate, promote businesses and upcoming events.

From the entire Xminger Multimedia co. ltd. team we say, Otumfour wo Nkwa so!</p>
<p className='text-xl  pt-4'>Piawwwwwww!</p>
<p className='text-xl  pt-4'>#Asanteman #OutdoorAdvertising #xminger #OutdoorAdvertising #billboard</p>
                <div>
                <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
                  </div> 
            </div>
        </div>
        </div>
       
       <Footer/>
    </div>
  )
}

export default News_four
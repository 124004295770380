import React from 'react'
import Footer from "./Footer"
import Banner from "./Banner"
import Navbar from "./Navbar"

function OurProjects() {
  return (
    <div>
       <Navbar/>
       <Banner/>
       <div className="container">
        <div className="row py-5">
        <div className="col-md-8 mx-auto">
        <h5 className='font-bold mt-5 text-xl sm:text-xl pb-2'>Our Project</h5>
               
    
            <div>
              <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
            </div>  
            </div>
        </div>
        </div>
            <Footer/>
    </div>
  )
}

export default OurProjects
import React from 'react'
import { Outlet } from 'react-router-dom'
import Banner from './Banner'
import Footer from './Footer'
import Navbar from './Navbar'
import Service from './Service'
import About from './About'
import Project from './Project'
// import News from './News'
// import Teams from './Teams'
import Contact from './Contact'


export default function Layout() {
  return (
    <div>
        <Navbar/>
        <Banner/>
        <About/>
        <Service/>
        <Project/>
        {/* <Teams/> */}
        <Outlet/>
        <Contact/>
        {/* <News/> */}
        <Footer/>
    </div>
  )
}

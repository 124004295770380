import React from 'react'
import Footer from "../Footer"
import Banner from "../Banner"
import Navbar from "../Navbar"
import Nkrumah from "../../images/xmin_kwamw.webp"

function News_two() {
  return (
    <div>
      <Navbar/>
        <Banner/>
        <div className="container">
        <div className="row py-10">
        <div className="col-md-8 mx-auto">
        <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5'>KWAME NKRUMAH UNIVERSITY OF SCIENCE AND TECHNOLOGY STUDENTS EXPLORE XMINGER ADVERTISING'S OPERATIONS</h5>
                <img src={Nkrumah} alt=""  className='about-img'/>
           
            <p className='text-xl pt-4'>In an effort to provide students with hands-on industry experience, a group of students from the department of Language and Communication Sciences at the Kwame Nkrumah University of Science and Technology (KNUST) embarked on a visit to Xminger Advertising. The primary objective of the visit was to gain a comprehensive and practical understanding of the company's operations and explore its various sections. This immersive experience would undoubtedly offer the students a valuable insight into the world of advertising.</p><p className='text-xl pt-4'> The visit commenced with an engaging presentation that introduced the students to Xminger Advertising Company Ltd. The company's history, vision, and notable achievements were highlighted, providing the students with a solid foundation. Students were given the opportunity to meet with the CEO of the company for more insight from the CEO’s perspective as well. The presentation emphasized the company's commitment to creativity, innovation, and delivering exceptional advertising solutions to clients.</p><p className='text-xl pt-4'> The students were also introduced to the various sections within the company, setting the stage for their exploration.

                Following the presentation, the students were taken to XmingerAdvertising's print house. Here, they had the opportunity to witness the printing process firsthand. </p><p className='text-xl pt-4'>They were introduced to the state-of-the-art printing machines and learned about the intricate techniques used to produce high-quality artworks. The students observed how digital files were received and meticulously transformed into vibrant prints. The visit to the print house offered the students a fascinating glimpse into the technical aspects of advertising production.

                The next stop on the tour was an exploration of XmingerAdvertising's billboard displays. </p><p className='text-xl pt-4'>Accompanied by the outdoor team, the students had the chance to observe the removal and installation of billboard artworks. They witnessed the team's expertise in handling large-scale advertising displays and gained insights into the logistics involved in managing such projects. The students also had the opportunity to view some of the company's major billboards, appreciating the impact of outdoor advertising on the urban landscape.

                The final leg of the visit took the students to XmingerAdvertising's workshop, where billboard fabrications were carried out. </p><p className='text-xl pt-4'>In this section, the students observed skilled craftsmen meticulously constructing billboard structures and frames. They learned about the materials, tools, and techniques involved in the fabrication process. The students gained an appreciation for the attention to detail and precision required to create robust and visually appealing billboards

                The visit to Xminger Advertising provided the students from Kwame Nkrumah University of Science and Technology with an exceptional opportunity to gain practical knowledge about the advertising industry. The company presentation set the stage by familiarizing the students with Xminger Advertising's history and vision. The visit to the print house showcased the intricacies of the printing process, while the exploration of billboard displays highlighted the significant role of outdoor advertising. Finally, the workshop visit allowed the students to witness the craftsmanship and dedication involved in billboard fabrication. By immersing themselves in the various sections of Xminger Advertising, the students gained a deeper understanding of the complexities and processes involved in the advertising industry. The visit not only enriched their knowledge but also ignited their passion for the field. Armed with firsthand experience and insights, the students departed XmingerAdvertising with a newfound appreciation for the art of advertising and its multifaceted nature. </p>
                 <div>
                 <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
                  </div>               
            </div>
        </div>
        </div>
       
       <Footer/>
    </div>
  )
}

export default News_two
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'

export default function Navbar() {

  const [open, setOpen] = useState(false)

  return (
    <nav className='bg-red-900 text-white fixed-top'>
      <div className='flex items-center font-medium justify-around'>
        <div className='z-50 p-4 md:w-auto w-full flex justify-between '>
          <img src={logo} alt="" className='md:cursor-pointer h-9 rounded shadow bg-slate-1000' />   
          <div className="text-4xl text-black md:hidden" onClick={() =>setOpen(!open)}>
          <ion-icon name={`${ open ? "close" : "menu"}`} ></ion-icon>
          </div>
        </div>
       
        <ul className='md:flex hidden items-center gap-8 font-[poppins]'>
          <li> <a href="/" className='py-7 px-3 inline-block'> Home </a> </li>     
          <li> <Link to="/about_us" className='py-6 px-3 inline-block'>About Us</Link> </li>
          <li> <a href="#services" className='py-6 px-3 inline-block'>Services</a> </li>
          <li><a href="#project" className='py-6 px-3 inline-block'>Projects</a> </li>   
          <li> <a href="#contact" className='py-6 px-3 inline-block'>Contact</a>  </li>
          <li> <a href="#blog" className='py-6 px-3 inline-block'>Blog</a> </li>
          {/* <NavLinks/> */}
        </ul>
       
        <ul className={
          `
          md:hidden bg-light text-black absolute w-full h-full bottom-0 py-24 pl-4 duration-500 ${open ? "left-0" : "left-[-100%]"}
          `
        } id='nav-ul'>
        
         <li> <a href="/" className='py-7 px-3 inline-block'> Home </a> </li>     
          <li> <Link to="/about_us" className='py-6 px-3 inline-block'>About Us</Link> </li>
          <li> <a href="/" className='py-6 px-3 inline-block'>Services</a> </li>
          <li><Link to="/projects" className='py-6 px-3 inline-block'>Projects</Link> </li>   
          <li> <a href="/" className='py-6 px-3 inline-block'>Contact</a>  </li>
          <li> <Link to="/" className='py-6 px-3 inline-block'>Blog</Link> </li>
        </ul>

      </div>

    </nav>
    
  )

  
}

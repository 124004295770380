import React, { useState } from 'react';

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="relative w-full h-64">
      <div
        className="w-full h-full bg-center bg-cover duration-200"
        style={{ backgroundImage: `url(${images[currentIndex]})` }}
      ></div>
      <div className="absolute top-1/2 left-5 transform -translate-y-1/2">
        <button
          onClick={prevSlide}
          className="text-white bg-black bg-opacity-50 p-2 rounded-full"
        >
          ‹
        </button>
      </div>
      <div className="absolute top-1/2 right-5 transform -translate-y-1/2">
        <button
          onClick={nextSlide}
          className="text-white bg-black bg-opacity-50 p-2 rounded-full"
        >
          ›
        </button>
      </div>
    </div>
  );
};

export default Carousel;
import React from 'react'
import logo from '../images/logo.png'
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faTiktok, faYoutube} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLocation, faPhone, faX } from '@fortawesome/free-solid-svg-icons';


export default function Footer() {
  // const myLinks =[
  //   {name: "Home", path: "service"},
  //   {name: "About Us", path: "project"},
  //   {name: "Services", path: "project"},
  //   {name: "Contact Us", path: "project"},
  //   {name: "Blog", path: "project"},
  // ];
  return (
    
    <div >
      {/* <div className="md:flex hidden container-fluid bg-gradient-to-r from-slate-200 to-red-200 text-slate-800 " id='footer-top'> */}
      <div className="md:flex  container-fluid bg-gradient-to-r from-slate-200 to-red-200 text-slate-800 " id='footer-top'>
       
        <div className="container p-5 font-[poppins]">
        <div className="row">
           <div className="col-md-4 items-center py-5">
           {/* <h4 className="text-left text-xl">
              The best place to grow your business
            </h4> */}
            <img src={logo} alt="" width={300}/>
            <div className='pt-5 text-sm'>
              <p className='text-justify'>We value all of our clients and hold them in high esteem. Xminger operates a "put yourself in their shoes" policy, which encourages every staff member to treat all clients as if they were the client themselves.</p>
            </div>
            
           </div>
           <div className="col-md-4 text-center  sm:border-x" >
           <h4 className='capitalize text-3xl font-bold hover:underline hover:uppercase'>Quick link</h4> 
            <div>
              <ul className='mp-5'>
              {/* {myLinks.map(myLinks =>(
                <li className='my-3 capitalize hover:text-red-600 '>
                 {myLinks.name}
                </li>
                 ))} */}

          <li className='my-3 capitalize hover:text-red-600'> <a href="/"> Home </a> </li>     
          <li className='my-3 capitalize hover:text-red-600'> <Link to="/about_us">About Us</Link> </li>
          <li className='my-3 capitalize hover:text-red-600'> <a href="#services">Services</a> </li>
          <li className='my-3 capitalize hover:text-red-600'><Link to="/projects">Projects</Link> </li>   
          <li className='my-3 capitalize hover:text-red-600'> <a href="#contact">Contact</a>  </li>
          <li className='my-3 capitalize hover:text-red-600'> <Link to="#blog">Blog</Link> </li>

                 {/* <li className='my-3 capitalize hover:text-red-600'><a href="/">Home</a></li>
                 <li className='my-3 capitalize hover:text-red-600'><a href="#about">About Us</a></li>
                 <li className='my-3 capitalize hover:text-red-600'><a href="#services">Services</a></li>
                 <li> <a href="/projects" className='my-3 capitalize hover:text-red-600k'>Projects</a></li>
                 <li className='my-3 capitalize hover:text-red-600'><a href="#contact">Contact Us</a></li>
                 <li className='my-3 capitalize hover:text-red-600'><a href="/#blog">Blog</a></li> */}
              </ul>
            </div>
           </div>
           <div className="col-md-4 text-center">
             <h4 className="capitalize text-3xl font-bold hover:underline hover:uppercase">Contact & Socials</h4>

             <div className="w-full sm:w-1/1 py-5">
             <div className="py-3">
  {/* <FontAwesomeIcon icon={fas.faHouse} /> */}
                <a href="https://www.facebook.com/share/TSva6oySiz5BpkPw/?mibextid=LQQJ4d">
                <FontAwesomeIcon className="text-4xl text-blue-500 img-zoom" icon={faFacebook} />
                </a>
                <a href="https://www.instagram.com/xminger_advertising.gh?igsh=MTA4eXRlemExOW96Yw%3D%3D&utm_source=qr" className="px-3">
                <FontAwesomeIcon className="text-4xl text-red-600 img-zoom" icon={faInstagram} />
                </a>
                
                <a href="https://x.com/xmingerad?s=21&t=IsXv8ySlg4Wi5zbYffI0Fw">
                <FontAwesomeIcon className="text-4xl img-zoom" icon={faX} />
                </a>
                
                <a href="https://www.linkedin.com/company/xminger-advertising-agency-gh/" className="px-3">
                <FontAwesomeIcon className="text-4xl text-blue-600 img-zoom" icon={faLinkedin} />
                </a>
                <a href="https://youtube.com/@xmingeradvertising7690?si=BzZdjviMlH6BgvQv" className=''>
                <FontAwesomeIcon className="text-4xl text-red-600 img-zoom" icon={faYoutube} />
                </a>
                
                <a href="https://www.tiktok.com/@xminger.advertising?_t=8p5GI2EVBi9&_r=1" className="px-3">
                <FontAwesomeIcon className="text-4xl text-slate-900 img-zoom" icon={faTiktok} />
                </a>
             </div>
              
                <hr  className='my-2'/>
          <div className='mt-2 mb-3 text-left'>
          <p className="pt-3 img-zoom"> <a href="#facebook" className=""> <FontAwesomeIcon className="text-xl text-red-600" icon={faPhone} /> </a> +233 (0) 322 023 430 | +233 (0) 266 116 627</p>
          <p className="py-3 img-zoom" ><a href="#facebook" className=""> <FontAwesomeIcon className="text-xl text-red-600" icon={faEnvelope} /></a> Xmingeradvertisingchannel@gmail.com</p>
          <p className='img-zoom'><a href="#facebook " className=""> <FontAwesomeIcon className="text-xl text-red-600" icon={faLocation} /></a>  No.16, Cedar Cresent Danyame, Kumasi</p>
          </div>
          
                
              </div>
                
           </div>
          
        </div>
        </div>
        </div>
        <div className="text-slate-50 bg-slate-900 text-center py-8">
          <small className='mx-3'>&copy; 2024 Xminger Advertising Company. All Rights Reserved.</small>
        </div>
      
    </div>
  )
}

import React from 'react'
import Footer from "../Footer"
import Banner from "../Banner"
import Navbar from "../Navbar"
import Kwame from "../../images/xminger-bill.webp"

function News_three() {
  return (
    <div>
      <Navbar/>
        <Banner/>
        <div className="container">
        <div className="row py-10">
        <div className="col-md-8 mx-auto">
        <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-5'>XMINGER BILLBOARD: A HERORIC GUARDIAN AT ANLOGA JUNCTION</h5>
               
                <img src={Kwame} alt="" className='about-img' />
          
            <p className='text-xl pt-4'>Situated in the heart of Anloga Junction, one of Xminger’s billboards which is strategically positioned to capture the attention of a large number of motorist and pedestrians passing by each day became more than just a marketing tool on December 7, 2023. On that fateful day, a trailer suddenly lost control which posed an imminent threat to lives and property in its path. </p>
            <p className='text-xl  pt-4'>However, fate intervened in the form of a sturdy Xminger Advertising billboard, standing tall and firm. As the trailer made its way forward, threatening to cause extensive damage, it collided with the Xminger billboard which acted as a formidable barrier making it evident how strong Xminger’s billboards are made. The robust billboard absorbed the impact of the collision, stopping the trailer’s progress and preventing it from causing further destruction.</p>
            <p className='text-xl  pt-4'> This incident serves as a testament to the importance of well-designed infrastructure in preserving public safety. The billboard’s heroic act will forever be remembered by the community as a remarkable example of how an ordinary structure can become an extraordinary savior in times of crises. </p>
            <div>
              <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
            </div>  
            </div>
        </div>
        </div>
       
       <Footer/>
    </div>
  )
}

export default News_three
import React from 'react'
import Footer from "./Footer"
import Banner from "./Banner"
import Navbar from "./Navbar"

function Billboard() {
  return (
    <div>
       <Navbar/>
       <Banner/>
       <div className="container">
        <div className="row py-2">
        <div className="col-md-8 mx-auto">
        <h5 className='font-bold mt-5 text-3xl sm:text-5xl pb-2 service-head-text'>Billboard Rentals</h5>

        <div>
                <p className='py-2 text-xl'>
                At Xminger Advertising, we have built an extensive network of over 1,000 high-quality billboards strategically placed across Ghana. Our boards are situated in prime locations with heavy vehicular and foot traffic, ensuring that your advertising campaigns reach the widest possible audience and deliver maximum return on your investment.
                 </p>
               <h6 className='font-bold mt-5 text-2xl sm:text-3xl pb-2 service-head-text'>Prime Locations for Brand Building</h6>
               <p  className='py-2 text-xl text-justify'>Xminger's billboards can be found in the most sought-after areas across Ghana's major towns and cities. We have carefully selected these prime locations to provide our clients with unparalleled visibility and the greatest opportunity to captivate and engage their target consumers.</p>
             
               <h6 className='font-bold mt-5 text-2xl sm:text-3xl pb-2 service-head-text'>Flexible Rental Terms</h6>
               <p className='py-2 text-xl text-justify'>Xminger's billboard spaces are available for rent on a standard 12-month basis, with the option to renew at the end of the term. To ensure the continued display of your visuals, we kindly request that you confirm any renewal at least 30 days prior to the expiration date.</p>
               
                <h6 className='font-bold mt-5 text-2xl sm:text-3xl pb-2 service-head-text'>Versatile Formats to Suit Your Needs</h6>
                <p className='py-2 text-xl text-justify'>Our advertising spaces come in a variety of sizes and orientations to accommodate your creative vision and marketing objectives. Choose from our selection of portrait and landscape formats, including:</p>
               <div className="row py-3">
                <table className='table-auto'>
                  <thead className=''>
                   <tr className='text-red-600 '>
                    <th className='border'> <h6  className='underline font-bold text-xl'>Portrait</h6></th>
                    <th><h6 className='underline font-bold text-xl text-right'>Landscape</h6></th>
                    <th></th>
                   </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='border py-1 text-xl'>9m x 6m</td>
                      <td className='border py-1 text-xl'>5m x 10m</td>
                      <td className='border py-1 text-xl'>9m x 18m</td>
                      
                    </tr>
                    <tr>
                      <td className=' border py-1 text-xl'>12m x 9m</td>
                      <td className='border py-1 text-xl'>6m x 12m</td>
                      <td className='border py-1 text-xl'>9m x 37m</td>
                      
                    </tr>
                    <tr>
                      <td className='border py-1 text-xl'>10m x 8m</td>
                      <td className='border py-1 text-xl'>8m x 16m</td>
                      <td className='border py-1 text-xl'>10m x 50m</td>
                      
                    </tr>
                  </tbody>
                </table>
                {/* <div className="col-6 text-red-600">
                  <h6  className='underline font-bold text-xl'>Portrait</h6>
                <ul className='py-1 text-xl'>
                  <li>9m x 6m</li>
                  <li>12m x 9m</li>
                  <li>10m x 8m</li>
                </ul>
                </div> */}
                {/* <div className="col-6 text-red-600">
                  <h6 className='underline font-bold text-xl'>Landscape</h6>
                <ul className='py-1 text-xl'>
                  <li>5m x 10m</li>
                  <li>6m x 12m</li>
                  <li>8m x 16m</li>
                </ul>
                
                </div> */}
               </div>
              </div> <div>
              <button className='btn bg-slate-900 text-slate-100 mt-5'><a href="javascript:history.back()">Go Back</a></button>
            </div>  
            </div>
        </div>
        </div>
         <Footer/> 
    </div>
  )
}

export default Billboard